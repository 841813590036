import { Button, Card, Flex, Select, Table, Tag, Space, Progress, Alert, Popconfirm, Result } from 'antd'
import { DownloadOutlined, UploadOutlined, CheckOutlined } from '@ant-design/icons';
import { CSVLink }          from "react-csv";
import React from 'react'
import useMain from './useMain';

const MainScreen = () => {

    const {
        buildings,
        tenants,
        onBuildingSelect,
        onTenantSelect,
        selectedTenant,
        selectedBuilding,
        validatedTableData,
        CSVTemplateData,
        handleFileUpload,
        onFileUploadClick,
        csvInputRef,
        tableData,
        progress,
        tableHeader,
        showSuccessMessage,
        showErrorMessage,
        fileHasError,
        logout,
        loader,
        contextHolder,
        openPopConfirm, setOpenPopConfirm,
        file,
        importData
    } = useMain();

    
      const onSearch = (value) => {
        console.log('search:', value);
      };


  return (
    <>
    {contextHolder}
    <div className='bg-slate-100 h-[100vh]'>
      <div className='flex justify-center pt-24 pb-24'>
        <div className='w-full sm:w-[70%] md:w-[60%] lg:w-[50%]'>
            <Card>
              <div className='flex sm:justify-between sm:items-center sm:flex-row w-auto flex-col'>
                <div>
                  <h4 className='text-xl font-medium'>Visitors Data Import</h4>
                  <p>Import past visitor data of sites based on tenant.</p>
                </div>
                <Button 
                  className='bg-red-400 hover:bg-sky-100' 
                  type='primary'
                  onClick={logout} 
                  
                >Logout</Button>
                </div>
                <div className='mt-5'>
                    <div className='flex flex-col gap-2 sm:flex-row'>
                      <Select
                          showSearch
                          placeholder="Select Site"
                          optionFilterProp="label"
                          disabled={loader.importing}
                          value={selectedBuilding}
                          onChange={onBuildingSelect}
                          onSearch={onSearch}
                          className='w-full sm:w-1/2'
                          options={buildings}
                      />
                      <Select
                          showSearch
                          placeholder="Select Tenant"
                          optionFilterProp="label"
                          disabled={loader.tenantsDropdown || loader.importing}
                          loading={loader.tenantsDropdown}
                          value={selectedTenant}
                          onChange={onTenantSelect}
                          onSearch={onSearch}
                          className='w-full sm:w-1/2'
                          options={tenants}
                      />
                    </div>
                    <div className='mt-3 flex flex-wrap justify-end gap-2'>
                      <CSVLink className='w-full sm:w-auto' data={CSVTemplateData} filename={`${selectedTenant?.label}_Template.csv`}>
                        <Button disabled={!selectedBuilding || !selectedTenant} className='w-full sm:w-auto' icon={<DownloadOutlined />}>Download Template</Button>
                      </CSVLink>
                      <input type="file" hidden ref={csvInputRef} accept=".csv" onChange={handleFileUpload} />
                      <Button disabled={!selectedBuilding || !selectedTenant || loader.importing} className='w-full sm:w-auto' type='primary' icon={<UploadOutlined />} onClick={onFileUploadClick}>Upload CSV</Button>
                      <Popconfirm
                          title="Import Data"
                          open={openPopConfirm}
                          description="Are you sure you want to import all?"
                          onConfirm={importData}
                          onCancel={() => setOpenPopConfirm(false)}
                          okText="Yes"
                          cancelText="No"
                        >
                        <Button 
                          disabled={!file || loader.importing || fileHasError} 
                          className='w-full sm:w-auto bg-sky-600 hover:bg-sky-100' 
                          type='primary'
                          onClick={() => setOpenPopConfirm(true)} 
                          icon={<CheckOutlined />}
                        >
                          Import Data
                        </Button>
                      </Popconfirm>
                    </div>
                    {loader.importing && 
                      <div>
                        <p>{progress !== 100 ? 'Importing Visitors ...' : 'Completed'}</p>
                        <Progress percent={progress} type="line" status={progress !== 100 ? 'active' : ''} /> 
                      </div>
                    }
                    <div className='mt-5'>
                        {fileHasError && <Alert
                          description="There are some some issues in data. Please check and try again."
                          type="error"
                          showIcon
                          className='mb-2'
                        /> }
                        {/* {showSuccessMessage && <Alert
                          description="Visitors Data Imported Successfully"
                          closable
                          type="success"
                          showIcon
                          className='mb-2'
                        /> } */}
                        {!showSuccessMessage && !showErrorMessage && <Table size='small' columns={tableHeader} dataSource={validatedTableData} scroll={{ x: '100%' }} /> }
                        {showSuccessMessage &&
                        <Result
                          status="success"
                          title="Successfully Imported Visitors Data"
                        /> }
                        {showErrorMessage &&
                        <Result
                          status="error"
                          title="Visitors Data Import Failed."
                          subTitle="Please contact development team to resolve the issue"
                        /> }
                    </div>
                </div>
            </Card>
        </div>
    </div>
    </div>
    </>
  )
}

export default MainScreen