import { useEffect, useRef, useState } from 'react';
import Ably from 'ably';
import { message } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import api from '../../api';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { checkIfEmpty, isValidDateTimeFormat } from '../../functions/functions';

const useMain = () => {

    // const BASE_URL = "http://localhost:8000/api/";
    const BASE_URL = "https://api.visitorpad.co.uk/api/";

    const csvInputRef = useRef();
    const [messageApi, contextHolder] = message.useMessage();

    const navigate = useNavigate();

    const [buildings, setBuildings] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState();
    const [selectedTenant, setSelectedTenant] = useState();
    const [CSVTemplateData, setCSVTemplateData] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [validatedTableData, setValidatedTableData] = useState([]);
    const [file, setFile] = useState();
    const [fileHasError, setFileHasError] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openPopConfirm, setOpenPopConfirm] = useState(false);
    const [loader, setLoader] = useState({
        tenantsDropdown : false,
        importing : false
    });

    useEffect(() => {
        fetchData();
    },[]);

    useEffect(() => {
        validateData();
    },[tableData]);

    useEffect(() => {
        const ably = new Ably.Realtime('GDni9A.iLJeIA:2o6qeQ4c0CqUIqv1igNWsJ54PyqtSUruyu097IToB60');
        const channel = ably.channels.get('import-progress');

        channel.subscribe('progress', (message) => {
            console.log(message);
            setProgress(message.data.progress);
        });

        return () => {
            channel.unsubscribe();
        };
    }, []);

    // When building is selected from dropdown
    const onBuildingSelect = async (value) => {
        if(showSuccessMessage) setShowSuccessMessage(false);
        if(showErrorMessage) setShowErrorMessage(false);
        setLoader({
            ...loader,
            tenantsDropdown : true
        })
        setSelectedBuilding(value);
        setSelectedTenant();
        await fetchTenants(value);
        setLoader({
            ...loader,
            tenantsDropdown : false
        })
    };

    // When tenant is selected from dropdown
    const onTenantSelect = async (value, option) => {
        if(showSuccessMessage) setShowSuccessMessage(false);
        if(showErrorMessage) setShowErrorMessage(false);
        setSelectedTenant(option);
        try {
            const response = await api.get(`${BASE_URL}admin/tenants/sign-in-form/${value}`);
            const tenant = response.data.data;
            const form = JSON.parse(tenant.form);
            console.log(form);
            prepareCSVTemplate(form.fields);
        } catch(err) {
            console.log(err);
        }
    }


    // Function to prepare CSV template to download
    const prepareCSVTemplate = (fields) => {
        let templateArray = [['Visitor Name']];
        console.log(fields);
        fields?.forEach((field) => {
            templateArray[0].push(field.name);
        })
        templateArray[0].push("Sign In Time");
        templateArray[0].push("Sign Out Time");

        console.log(templateArray);
        setCSVTemplateData(templateArray);

    }

    // Get Tenants of a particular site
    const fetchTenants = async (value) => {
        try {
            const response = await api.get(`${BASE_URL}admin/buildings/${value}/tenants`);
            const tenants = response.data.data;

            let tenantsArray = [];
            tenants.forEach(tenant => {
                tenantsArray.push({
                    value : tenant.tenant_id,
                    label : tenant.name
                })
            });

            tenantsArray.sort((a,b) => {
                if(a.label < b.label) {
                    return -1;
                }
                if(a.label > b.label) {
                    return 0;
                }
            })
            
            setTenants(tenantsArray)
            
        } catch(err) {

        }
    }

    // Function to import CSV file
    const importData = async () => {
        setOpenPopConfirm(false);
        setLoader({
            ...loader,
            importing : true
        })

        try {
            const formData = new FormData();

            formData.append("imported_file", file);
            formData.append("building_id", selectedBuilding);
            formData.append("tenant_id", selectedTenant.value);

            const config = {
                headers : {'Content-Type' : 'multipart/form-data'},
            }

            const response = await api.post(`${BASE_URL}admin/visitors/bulk-import`, formData, config);
            console.log(response);
            messageApi.open({
                type: 'success',
                content: 'Visistors data imported successfully',
                duration: 5,
            });

            setShowSuccessMessage(true);
            

        } catch(err) {
            const errorData = err?.response?.data;
            if(err.response.status === 422) {
                if(errorData.errors.tenant_id) {
                   showMessage('Select a tenant');
                }
                if(errorData.errors.building_id) {
                    setTimeout(() => {
                        showMessage('Select a building');
                    }, 500) 
                }
                if(errorData.errors.imported_file) {
                    setTimeout(() => {
                        showMessage('Select a valid CSV file');
                    }, 1000) 
                }
            } else {
                showMessage('Something Went Wrong');
                setShowErrorMessage(true);
            }
        }
        setTimeout(() => {
            setLoader({
                ...loader,
                importing : false
            });
            setFile('');
            setTableHeader([]);
            setTableData([]);
            setProgress(0);
        }, 3000);
        
    }

    const validateData = () => {
        let validateDataArray = [];
        tableData.forEach((data) => {
            let formattedData = {
                'Visitor Name' : data['Visitor Name'].trim(),
                'Sign In Time' : data['Sign In Time'].trim(),
                ...data,
            }

            let errorObj = {
                name : false,
                sign_in_time : false
            }

            // Checking if name is empty or not
            if(checkIfEmpty(formattedData['Visitor Name'])) {
                errorObj.name = true;
            }

            if(checkIfEmpty(formattedData['Sign In Time'])) {
                errorObj.sign_in_time = true;
            }

            // if(!isValidDateTimeFormat(formattedData['Sign In Time'])) {
            //     errorObj.sign_in_time = true;
            // }

            const hasError = Object.values(errorObj).some(value => value === true);

            if(hasError) {
                formattedData['error'] = true;
            }
        
            validateDataArray.push({
                'valid' : formattedData.error ? <CloseCircleFilled className='text-red-600' /> : <CheckCircleFilled className='text-green-500' />,
                ...formattedData
            });

        });

        const fileError = validateDataArray.some(obj => obj.error === true);
        setFileHasError(fileError);
        setValidatedTableData(validateDataArray);
        prepareTableData(validateDataArray);

        console.log(validateDataArray)

    }

    const showMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: 5,
        });
    }

    // Get All Buildings
    const fetchData = async () => {
        try {
            const response = await api.get(`${BASE_URL}admin/get-sites`);
            const buildings = response.data.data;
            let buildingsArray = [];
            buildings.forEach(building => {
                buildingsArray.push({
                    value : building.building_id,
                    label : building.name
                })
            });

            buildingsArray.sort((a,b) => {
                if(a.label < b.label) {
                    return -1;
                }
                if(a.label > b.label) {
                    return 0;
                }
            })

            setBuildings(buildingsArray)
            
        } catch(err) {
            // if(err.response.status === 401) {
            //     messageApi.open({
            //         type: 'error',
            //         content: 'Not Authenticated, Login Again',
            //         duration: 5,
            //     });
            // }

            // navigate('/')

            
        }
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    }

    // When Upload CSV Button is clicked
    const onFileUploadClick = () => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        csvInputRef.current.click();
    }

    // When CSV File is uploaded
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            header : true,
            skipEmptyLines : 'greedy',
            complete : (results) => {
    
                const headers = results.meta.fields;
                const data = results.data;

                let headerArray = [];
                let dataArray = [];

                headerArray.push({
                    title : 'Valid',
                    dataIndex : 'valid',
                    key : 'valid'
                })

                // Preparing Headers for Preview Table
                headers.forEach((item) => {
                    headerArray.push({
                        title : item,
                        dataIndex : item,
                        key : item
                    })
                });

                // Preparing Data for Preview Table
                data.forEach((item, index) => {
                    dataArray.push({
                        key : index,
                        ...item
                    })
                })

                setTableHeader(headerArray);
                setTableData(dataArray);
                setFile(file);
                csvInputRef.current.value = null;
            }
        })
    }

    const prepareTableData = () => {

    }

    return {
        buildings,
        onBuildingSelect,
        onTenantSelect,
        selectedTenant,
        selectedBuilding,
        showSuccessMessage,
        CSVTemplateData,
        tenants,
        csvInputRef,
        onFileUploadClick,
        handleFileUpload,
        tableData,
        tableHeader,
        importData,
        progress,
        logout,
        showErrorMessage,
        contextHolder,
        validatedTableData,
        file,
        fileHasError,
        openPopConfirm, setOpenPopConfirm,
        loader
    }
}

export default useMain