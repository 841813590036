import React from 'react';
import { Route, Routes, BrowserRouter, Navigate, useNavigate }     from 'react-router-dom';
import { ConfigProvider } from 'antd';
import LoginScreen from './pages/login/login';
import MainScreen from './pages/main/main';
import RedirectRoute from './RedirectRoute';
import ProtectedRoute from './ProtectedRoute';

function App() {

  return (
  <BrowserRouter basename='/'>
    <ConfigProvider     // AntDesign Config Provider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#0f0f0f',  
          borderRadius: 5,  // Change border radius
          controlOutline: '0 0 0 2px rgba(0, 0, 0, 0.2)',
          colorBorder : '#d4d4d4',
              // To change the input focus color
        },
      }}
      card={{
        colorBorderSecondary : '#0f0f0f'
      }}
    >
      <Routes>
        <Route element={<RedirectRoute />}>
          <Route path="/" element={<LoginScreen />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/main" element={<MainScreen />} />
        </Route>
      </Routes>
      </ConfigProvider>
   </BrowserRouter>
  );
}

export default App;