import React from 'react'
import { Flex, Form, Card, Typography, Input, Button, Alert  } from 'antd';
import useLogin from './useLogin';
const { Title } = Typography;


const LoginScreen = () => {

    const {
        onFormSuccessSubmit,
        loginError,
        form,
        loader
    } = useLogin();

  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-100'>
        <Card
            bordered={false}
            className='w-[500px]'
        >
            <Title level={3}>Login to Portal</Title>
            {loginError && <Alert message={loginError} type="error" className="mb-4" />}
            <Form
                name="login"
                form={form}
                initialValues={{ remember: true }}
                layout="vertical" // This makes the label appear above the input
                onFinish={onFormSuccessSubmit}
                >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <div className='flex justify-end'>
                        <Button disabled={loader} block size='large' type='primary' htmlType='submit'>Login</Button>
                    </div>
                </Form.Item>
            </Form>
        </Card>
    </div>
  )
}

export default LoginScreen