import { Outlet, Navigate } from 'react-router-dom'

const RedirectRoute = () => {
    const token = localStorage.getItem('token');

    return (
        <>
            {token ? <Navigate to="/main" /> : <Outlet />}
        </>
    )
}

export default RedirectRoute